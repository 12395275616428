.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 320px;
  width: auto;
}

.carousel .slide {
  background: none;
}

.carousel.carousel-slider {
  overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
  background-color: transparent;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
  background-color: transparent;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.carousel .control-next.control-arrow:before {
  content: "";
  border: solid #48cae4;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 7px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  opacity: 0.5;
}

.carousel .control-prev.control-arrow:before {
  content: "";
  border: solid #48cae4;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 7px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  opacity: 0.5;
}
